import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getAuth, connectAuthEmulator} from 'firebase/auth';
import {getStorage, connectStorageEmulator} from 'firebase/storage';
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';
import {connectFirestoreEmulator} from 'firebase/firestore';
import {initializeFirestore} from 'firebase/firestore';

const isDev = process.env.NODE_ENV === 'development';

const firebaseConfig = {
    apiKey: 'AIzaSyAqEPmYBOcl1kCd9PEdGmW1tBY4FkyK0Ag',
    authDomain: 'embertracking-demo.firebaseapp.com',
    projectId: 'embertracking-demo',
    storageBucket: 'embertracking-demo.firebasestorage.app',
    messagingSenderId: '845423463199',
    appId: '1:845423463199:web:9e369085441a89013e7e2a'
};

export const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, {ignoreUndefinedProperties: true});
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const functions = getFunctions(app, 'northamerica-northeast1');
export const storage = getStorage(app);

if (isDev) {
    const localhost = '127.0.0.1';

    connectAuthEmulator(auth, `http://${localhost}:9099`);
    connectFunctionsEmulator(functions, localhost, 5001);
    connectFirestoreEmulator(db, localhost, 8080);
    connectStorageEmulator(storage, localhost, 9199);
}

export default app;